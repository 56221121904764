const initialState = () => {
  return {
    showCartPopup: false,
    showProductAdded: false,
    addToCartError: null,
    cartMessage: null,
    cartTotals: null,
    loadingCartTotals: false,
    shippingMethods: [],
    headerTotals: null,
    defaultCarrierCode: null,
    defaultMethodCode: null,
  }
}

const state = initialState()

const mutations = {
  SET_CART_TOTALS(state, data) {
    state.cartTotals = data
  },
  SET_HEADER_TOTALS(state, data) {
    state.headerTotals = data
  },
  SET_LOADING_TOTALS(state, data) {
    state.loadingCartTotals = data
  },
  SET_SHIPPING_METHODS(state, shippingMethods) {
    state.shippingMethods = shippingMethods
  },
  SHOW_CART_POPUP(state, payload) {
    state.showCartPopup = payload
  },
  SHOW_PRODUCT_ADDED(state, payload) {
    state.showProductAdded = payload
  },
  SET_CART_MESSAGE(state, message) {
    state.cartMessage = message
  },
  ADD_TO_CART_ERROR(state, payload) {
    state.addToCartError = payload
  },
  SET_DEFAULT_CARRIER_DATA(state, data) {
    state.defaultMethodCode = data['methodCode']
    state.defaultCarrierCode = data['carrierCode']
  },
}

const actions = {
  async addProductToQuote({dispatch}, payload: object): Promise<void> {
    await dispatch('addBulkProductsToQuote', [{
      sku: payload['sku'],
      qty: payload['qty'],
      product_option: payload['product_option']
    }]);
  },
  async addBulkProductsToQuote({rootState, commit, dispatch}, payload: Array<object>): Promise<void> {
    commit('SET_LOADING_TOTALS', true)
    commit('ADD_TO_CART_ERROR', false)

    try {
      const {data} = await this.$solarStatefulClient.post('/checkout/cart/items', {
        cartItems: payload
      })

      commit('SET_CART_TOTALS', data)
      commit('SET_HEADER_TOTALS', data)
      commit('SHOW_CART_POPUP', true)
      commit('SHOW_PRODUCT_ADDED', true)
      commit('CheckoutGlobal/SET_LAST_ADDED_ITEM', data['extension_attributes']['last_added_item'], {root: true})
      commit('CheckoutGlobal/SET_QUOTE_ITEMS', data.items, {root: true})
      commit('CheckoutGlobal/SET_QUOTE_ITEM_COUNT', data.items_qty, {root: true})

      await dispatch('collectCartTotals', false)
    } catch ({ response }) {
      let message = response.data?.message || response.data?.error || 'Could not add product to cart'

      rootState.CheckoutCart.addToCartError = true
      rootState.CheckoutCart.cartMessage = message
    } finally {
      commit('SET_LOADING_TOTALS', false)
    }
  },
  async updateQuoteItemQty({commit, dispatch}, cartItem: object): Promise<any> {
    commit('SET_LOADING_TOTALS', true)

    try {
      const {data} = await this.$solarStatefulClient.put(`/checkout/cart/items/${cartItem['item_id']}`, {
        cartItem: {sku: cartItem['sku'] || cartItem['extension_attributes']['sku'], qty: cartItem['qty']}
      })

      commit('SET_CART_TOTALS', data)
      commit('SET_HEADER_TOTALS', data)
      commit('CheckoutGlobal/SET_QUOTE_ITEMS', data.items, {root: true})
      commit('CheckoutGlobal/SET_QUOTE_ITEM_COUNT', data.items_qty, {root: true})

      await dispatch('collectCartTotals', false)
    } catch ({ response }) {
      console.error(response)
    } finally {
      commit('SET_LOADING_TOTALS', false)
    }
  },
  async deleteQuoteItem({commit, dispatch}, cartItemId: number): Promise<void> {
    commit('SET_LOADING_TOTALS', true)

    try {
      const {data} = await this.$solarStatefulClient.delete(`/checkout/cart/items/${cartItemId}`)

      commit('SET_CART_TOTALS', data)
      commit('SET_HEADER_TOTALS', data)
      commit('CheckoutGlobal/SET_QUOTE_ITEMS', data.items, {root: true})
      commit('CheckoutGlobal/SET_QUOTE_ITEM_COUNT', data.items_qty, {root: true})

      await dispatch('collectCartTotals', false)
    } catch ({ response }) {
      console.error(response)
    } finally {
      commit('SET_LOADING_TOTALS', false)
    }
  },
  async collectCartTotals({commit}, showLoader: boolean): Promise<void> {
    if (showLoader) {
      commit('SET_LOADING_TOTALS', true)
    }

    try {
      const {data} = await this.$solarStatefulClient.get(`/checkout/mini-cart`)

      commit('SET_CART_TOTALS', data)
      commit('SET_HEADER_TOTALS', data)
      commit('CheckoutGlobal/SET_QUOTE_ITEMS', data.items, {root: true})
      commit('CheckoutGlobal/SET_QUOTE_ITEM_COUNT', data.items_qty, {root: true})
    } catch (err) {
      console.error(err)
    } finally {
      if (showLoader) {
        commit('SET_LOADING_TOTALS', false)
      }
    }
  },
  async fetchCartTotals({commit, rootState, rootGetters}, step: string): Promise<any> {
    commit('SET_LOADING_TOTALS', true)
    let [_billingAddress, _shippingAddress] = rootGetters['CheckoutCheckout/getAddressData']

    if (rootGetters['CheckoutGlobal/isLoggedIn']) {
      delete _shippingAddress['id']
    }

    if (step !== 'cart' && ! _shippingAddress['email']) {
      _shippingAddress['email'] = rootState.CheckoutGlobal.customerEmail
    }

    try {
      const {data} = await this.$solarStatefulClient.put(`/checkout/cart/totals-information`, {
        addressInformation: {
          address: _shippingAddress,
          shipping_method_code: rootGetters['CheckoutShipping/shippingMethodMethodCode'],
          shipping_carrier_code: rootGetters['CheckoutShipping/shippingMethodCarrierCode'],
        }
      })

      commit('SET_CART_TOTALS', data)
      commit('SET_HEADER_TOTALS', data)
      commit('CheckoutGlobal/SET_QUOTE_ITEMS', data['items'] || [], {root: true})
      commit('CheckoutGlobal/SET_QUOTE_ITEM_COUNT', data['items_qty'] || 0, {root: true})

      if (step !== 'cart') {
        const outOfStock = data['items'].filter((item: object) => {
          return !item['extension_attributes']['product_stock'] || item['extension_attributes']['product_stock'] < item['qty']
        })
        if (outOfStock.length) {
          window.location.replace('checkout/cart')
        }
      }

      return data
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING_TOTALS', false)
    }
  },
  async collectDefaultShippingMethods({rootGetters, commit}): Promise<void> {
    try {
      const [_billingAddress, _shippingAddress] = rootGetters['CheckoutCheckout/convertAddressData']
      const {data} = await this.$solarStatefulClient.post(`/checkout/cart/estimate-shipping-methods`, {
        address: _shippingAddress
      })

      if (data.length > 0) {
        commit('SET_DEFAULT_CARRIER_DATA', {
          methodCode: data[0]['method_code'],
          carrierCode: data[0]['carrier_code'],
        })
      }
    } catch (e) {
      console.error(e)
    }
  },
  showCartPopup({state, commit, dispatch}, status) {
    commit('SHOW_CART_POPUP', status)
    if(status && null === state.headerTotals) {
      try {
        dispatch('fetchCartTotals')
      } catch (e) {
        console.error(e)
      }
    }
  },
  showProductAdded({commit}, payload) {
    commit('SHOW_PRODUCT_ADDED', payload)
  },
}

const getters = {
  cartTotals: state => state.cartTotals,
  headerTotals: state => state.headerTotals,
  loadingCartTotals: state => state.loadingCartTotals,
  showCartPopup: state => state.showCartPopup,
  showProductAdded: state => state.showProductAdded,
  cartHasItems: (state, getters, rootState) => rootState.CheckoutGlobal.quoteItemCount > 0,
}

const CheckoutCart = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutCart
